<template>
    <svg width="1em" viewBox="0 0 530 110" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M322.721 44V37.4375L343.346 9.9091H322.806V0.363647H358.431V6.92615L337.806 34.4546H358.346V44H322.721Z" fill="currentColor"/>
        <path d="M280.166 9.9091V0.363647H318.092V9.9091H304.967V44H293.291V9.9091H280.166Z" fill="currentColor"/>
        <path d="M250.449 44H237.664L252.068 0.363647H268.261L282.664 44H269.88L260.335 12.3807H259.994L250.449 44ZM248.062 26.7841H272.096V35.6477H248.062V26.7841Z" fill="currentColor"/>
        <path d="M204.836 44V0.363647H216.683V34.4546H234.325V44H204.836Z" fill="currentColor"/>
        <path d="M165.461 44V0.363647H184.296C187.535 0.363647 190.369 1.00285 192.798 2.28126C195.227 3.55967 197.116 5.35654 198.465 7.67189C199.815 9.98723 200.489 12.6932 200.489 15.7898C200.489 18.9148 199.793 21.6207 198.401 23.9077C197.023 26.1946 195.085 27.956 192.585 29.1918C190.099 30.4276 187.194 31.0455 183.87 31.0455H172.62V21.8409H181.484C182.876 21.8409 184.062 21.5994 185.042 21.1165C186.036 20.6193 186.796 19.9162 187.322 19.0071C187.862 18.098 188.131 17.0256 188.131 15.7898C188.131 14.5398 187.862 13.4744 187.322 12.5938C186.796 11.6989 186.036 11.0171 185.042 10.5483C184.062 10.0654 182.876 9.82387 181.484 9.82387H177.308V44H165.461Z" fill="currentColor"/>
        <path d="M122.57 44V0.363647H134.417V18.0057H135.013L148.138 0.363647H161.945L147.201 19.7955L162.286 44H148.138L138.337 27.6364L134.417 32.75V44H122.57Z" fill="currentColor"/>
        <path d="M82.4922 44V0.363647H101.327C104.566 0.363647 107.4 0.953136 109.829 2.13211C112.258 3.31109 114.147 5.00853 115.496 7.22444C116.846 9.44035 117.521 12.0966 117.521 15.1932C117.521 18.3182 116.825 20.9531 115.433 23.098C114.055 25.2429 112.116 26.8622 109.616 27.956C107.13 29.0497 104.225 29.5966 100.901 29.5966H89.6513V20.3921H98.5149C99.907 20.3921 101.093 20.2216 102.073 19.8807C103.067 19.5256 103.827 18.9645 104.353 18.1975C104.893 17.4304 105.163 16.429 105.163 15.1932C105.163 13.9432 104.893 12.9276 104.353 12.1463C103.827 11.3509 103.067 10.7685 102.073 10.3992C101.093 10.0156 99.907 9.82387 98.5149 9.82387H94.3388V44H82.4922ZM108.06 23.9716L118.969 44H106.1L95.4467 23.9716H108.06Z" fill="currentColor"/>
        <path d="M46.0735 44H33.2894L47.6928 0.363647H63.886L78.2894 44H65.5053L55.9599 12.3807H55.619L46.0735 44ZM43.6872 26.7841H67.7212V35.6477H43.6872V26.7841Z" fill="currentColor"/>
        <path d="M0.8125 44V0.363647H19.6477C22.8864 0.363647 25.7202 1.00285 28.1491 2.28126C30.5781 3.55967 32.4673 5.35654 33.8168 7.67189C35.1662 9.98723 35.8409 12.6932 35.8409 15.7898C35.8409 18.9148 35.1449 21.6207 33.7528 23.9077C32.375 26.1946 30.4361 27.956 27.9361 29.1918C25.4503 30.4276 22.5455 31.0455 19.2216 31.0455H7.97159V21.8409H16.8352C18.2273 21.8409 19.4134 21.5994 20.3935 21.1165C21.3878 20.6193 22.1477 19.9162 22.6733 19.0071C23.2131 18.098 23.483 17.0256 23.483 15.7898C23.483 14.5398 23.2131 13.4744 22.6733 12.5938C22.1477 11.6989 21.3878 11.0171 20.3935 10.5483C19.4134 10.0654 18.2273 9.82387 16.8352 9.82387H12.6591V44H0.8125Z" fill="currentColor"/>
        <path d="M490.901 104V97.4375L511.526 69.9091H490.986V60.3636H526.611V66.9261L505.986 94.4546H526.526V104H490.901Z" fill="currentColor"/>
        <path d="M448.346 69.9091V60.3636H486.272V69.9091H473.147V104H461.471V69.9091H448.346Z" fill="currentColor"/>
        <path d="M443.925 60.3636V104H432.078V60.3636H443.925Z" fill="currentColor"/>
        <path d="M426.474 60.3636V104H416.588L400.821 81.0739H400.565V104H388.719V60.3636H398.776L414.287 83.2046H414.628V60.3636H426.474Z" fill="currentColor"/>
        <path d="M348.641 104V60.3636H367.476C370.714 60.3636 373.548 60.9531 375.977 62.1321C378.406 63.3111 380.295 65.0085 381.645 67.2244C382.994 69.4404 383.669 72.0966 383.669 75.1932C383.669 78.3182 382.973 80.9531 381.581 83.098C380.203 85.2429 378.264 86.8622 375.764 87.956C373.278 89.0497 370.374 89.5966 367.05 89.5966H355.8V80.3921H364.663C366.055 80.3921 367.241 80.2216 368.222 79.8807C369.216 79.5256 369.976 78.9645 370.501 78.1975C371.041 77.4304 371.311 76.429 371.311 75.1932C371.311 73.9432 371.041 72.9276 370.501 72.1463C369.976 71.3509 369.216 70.7685 368.222 70.3992C367.241 70.0156 366.055 69.8239 364.663 69.8239H360.487V104H348.641ZM374.209 83.9716L385.118 104H372.249L361.595 83.9716H374.209Z" fill="currentColor"/>
        <path d="M312.222 104H299.438L313.841 60.3636H330.034L344.438 104H331.654L322.108 72.3807H321.767L312.222 104ZM309.836 86.7841H333.87V95.6477H309.836V86.7841Z" fill="currentColor"/>
        <path d="M255.887 104V60.3636H267.733V77.4091H283.415V60.3636H295.262V104H283.415V86.9546H267.733V104H255.887Z" fill="currentColor"/>
        <path d="M250.512 76.7273H238.495C238.41 75.733 238.183 74.831 237.813 74.0213C237.458 73.2117 236.961 72.5156 236.322 71.9333C235.697 71.3367 234.937 70.8821 234.042 70.5696C233.147 70.2429 232.131 70.0796 230.995 70.0796C229.006 70.0796 227.323 70.5625 225.945 71.5284C224.582 72.4943 223.545 73.8793 222.835 75.6833C222.138 77.4872 221.79 79.6534 221.79 82.1818C221.79 84.8523 222.146 87.0895 222.856 88.8935C223.58 90.6833 224.624 92.0327 225.988 92.9418C227.352 93.8367 228.992 94.2841 230.91 94.2841C232.004 94.2841 232.984 94.1492 233.85 93.8793C234.717 93.5952 235.469 93.1904 236.109 92.6648C236.748 92.1392 237.266 91.5071 237.664 90.7685C238.076 90.0156 238.353 89.1705 238.495 88.233L250.512 88.3182C250.37 90.1648 249.852 92.0469 248.957 93.9645C248.062 95.8679 246.79 97.6293 245.143 99.2486C243.509 100.854 241.485 102.146 239.07 103.126C236.656 104.107 233.85 104.597 230.654 104.597C226.648 104.597 223.055 103.737 219.873 102.018C216.705 100.3 214.198 97.7713 212.352 94.4333C210.519 91.0952 209.603 87.0114 209.603 82.1818C209.603 77.3239 210.54 73.233 212.415 69.9091C214.29 66.571 216.819 64.0497 220.001 62.3452C223.183 60.6264 226.734 59.7671 230.654 59.7671C233.41 59.7671 235.945 60.1435 238.261 60.8963C240.576 61.6492 242.607 62.75 244.354 64.1989C246.102 65.6335 247.508 67.402 248.573 69.5043C249.638 71.6065 250.285 74.0142 250.512 76.7273Z" fill="currentColor"/>
        <path d="M193.335 74C193.222 72.5796 192.689 71.4716 191.737 70.6762C190.8 69.8807 189.372 69.483 187.455 69.483C186.233 69.483 185.232 69.6321 184.45 69.9304C183.683 70.2145 183.115 70.6051 182.746 71.1023C182.376 71.5994 182.185 72.1676 182.17 72.8068C182.142 73.3324 182.234 73.8083 182.447 74.2344C182.675 74.6463 183.03 75.0227 183.513 75.3637C183.996 75.6904 184.614 75.9887 185.366 76.2585C186.119 76.5284 187.014 76.7699 188.051 76.983L191.631 77.75C194.045 78.2614 196.112 78.9361 197.831 79.7742C199.55 80.6122 200.956 81.5994 202.05 82.7358C203.143 83.858 203.946 85.1222 204.457 86.5284C204.983 87.9347 205.253 89.4688 205.267 91.1307C205.253 94 204.535 96.429 203.115 98.4176C201.695 100.406 199.663 101.919 197.021 102.956C194.393 103.993 191.233 104.511 187.54 104.511C183.747 104.511 180.437 103.95 177.611 102.828C174.798 101.706 172.611 99.9801 171.048 97.6506C169.5 95.3068 168.719 92.3097 168.705 88.6591H179.955C180.026 89.9943 180.359 91.1165 180.956 92.0256C181.553 92.9347 182.391 93.6236 183.47 94.0923C184.564 94.5611 185.864 94.7955 187.369 94.7955C188.634 94.7955 189.692 94.6392 190.544 94.3267C191.396 94.0142 192.043 93.581 192.483 93.027C192.923 92.473 193.151 91.8409 193.165 91.1307C193.151 90.4631 192.93 89.8807 192.504 89.3835C192.092 88.8722 191.41 88.4176 190.459 88.0199C189.507 87.608 188.222 87.2244 186.602 86.8693L182.256 85.9318C178.392 85.0938 175.345 83.6946 173.115 81.7344C170.899 79.76 169.798 77.0682 169.812 73.6591C169.798 70.8892 170.537 68.4673 172.028 66.3935C173.534 64.3054 175.615 62.679 178.271 61.5142C180.942 60.3494 184.003 59.7671 187.455 59.7671C190.977 59.7671 194.024 60.3565 196.595 61.5355C199.166 62.7145 201.148 64.3764 202.54 66.5213C203.946 68.652 204.656 71.1449 204.67 74H193.335Z" fill="currentColor"/>
    </svg>
</template>
