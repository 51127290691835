<template>
    <div>
        <Navbar />
        <main>
            <section class="relative w-full h-full min-h-screen">
                <slot />
            </section>
        </main>
        <footer class="footer footer-center p-10 bg-base-200 text-base-content rounded">
            <nav>
                <div class="grid grid-flow-col gap-4">
                    <Link :href="route('index')">
                        <ApplicationLogo class="py-4 text-primary text-[10rem]" />
                    </Link>
                </div>
            </nav>
            <aside>
                <p>Copyright © 2024</p>
                <p>office@parkplatz-scharnitz.at</p>
            </aside>
        </footer>
    </div>
</template>

<script>
import Navbar from '@/Components/Navbar/Navbar.vue';
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import { Link } from '@inertiajs/vue3';

export default {
    name: 'Layout',
    components: {
        ApplicationLogo,
        Navbar,
        Link
    },
};
</script>
